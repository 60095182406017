.inputM {
    width: 100%;
}

.centeralign{
    text-align: center;
}

.btnwidth{
    width: 75%;
}